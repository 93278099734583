module.exports = [{
      plugin: require('/Users/tcpoyilmaz/Documents/projects/jsakademi-client/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('/Users/tcpoyilmaz/Documents/projects/jsakademi-client/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/tcpoyilmaz/Documents/projects/jsakademi-client/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
